<!-- 账户安全 -->
<template>
  <div>
    <div class="account">
      <p class="title">账号信息</p>
      <div class="info_box" v-if="false">
        <div class="info">
          <span class="name">个性域名</span>
          <span class="default" v-if="!userInfo.domain">就是个性......</span>
          <span>{{ userInfo.domain }}</span>


          <span class="content"></span>
        </div>
        <el-button type="primary" size="mini" class="edit" @click="clickEdit('绑定个性域名', '个性域名')">
          编辑
        </el-button>
      </div>
      <div class="info_box" v-if="false">
        <div class="info">
          <span class="name">电子邮箱</span>
          <span class="default" v-if="!userInfo.email">666....888@qq.com</span>
          <span>{{ userInfo.email }}</span>

          <span class="content"></span>
        </div>
        <el-button type="primary" size="mini" class="edit" @click="clickEdit('绑定电子邮箱', '电子邮箱')">
          编辑
        </el-button>
      </div>
      <div class="info_box">
        <div class="info">
          <span class="name">手机号</span
          ><span class="content">{{ userInfo.phone }}</span>
        </div>
        <el-button type="primary" size="mini" class="edit" @click="bindPhone">编辑</el-button>
      </div>
      <div class="info_box">
        <div class="info">
          <span class="name">密码</span><span class="content">{{userType==0?'':'*********'}}</span>
        </div>
        <el-button type="primary" size="mini" class="edit" v-if="userType!=0" @click="clickEdit('重置密码', '重置密码')">重置 </el-button>
         <el-button type="primary" size="mini" class="edit" v-else @click="clickEdit('设置密码', '设置密码')">设置 </el-button>
      </div>
    </div>

    <!-- 个性域名 邮箱绑定 -->
    <el-dialog
      :title="title"
      :visible.sync="domainShow"
      width="30%"
      :before-close="handleClose"
      top="30vh"
      :show-close="false"
    >
      <div class="ipt">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          v-if="name !== '重置密码'"
        >
          <el-form-item :label="name" prop="domainValue">
            <el-input v-model="ruleForm.domainValue"></el-input>
          </el-form-item>
        </el-form>
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          v-if="name == '重置密码'"
        >
        <!-- <el-input v-model="signData.phone" placeholder="手机号"></el-input>
          <div class="ipt">
            <el-input v-model="signData.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCode">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div> -->

          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm2.phone" placeholder="手机号"></el-input>
          </el-form-item>

          <el-form-item label="短信验证码" prop="code">
            <div class="ipt">
            <el-input v-model="ruleForm2.code" placeholder="短信验证码"></el-input><span class="code" @click="clickGetCodereset">{{
              show ? "获取验证码" : count + "s"
            }}</span>
          </div>
          </el-form-item>


          <!-- <el-form-item label="旧密码" prop="old_password">
            <el-input
              v-model="ruleForm2.old_password"
              type="password"
              maxlength="20"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="新密码" prop="new_password">
            <el-input
              v-model="ruleForm2.new_password"
              type="password"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="determine('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 绑定手机号 -->
    <el-dialog
      title="绑定手机号"
      :visible.sync="verificationShow"
      width="20%"
      :center="true"
      top="20vh"
    >
      <div class="ipts">
        <el-input v-model="phone" placeholder="手机号"></el-input>
        <div class="bindipt">
          <el-input v-model="code" placeholder="短信验证码"></el-input
          ><span class="code" @click="clickGetCode">{{
            show ? "获取验证码" : count + "s"
          }}</span>
        </div>
      </div>
      <span class="bindPhone">
        <el-button type="primary" @click="determinePhon">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="设置密码" :visible.sync="setpasswordShow" width="18%" :center="true" top="20vh" @close="setpasswordShow=false">
      <div class="ipts">
        <el-input v-model="password" placeholder="密码" type="password"></el-input>
      </div>
      <span class="bindPhone">
        <el-button type="primary" @click="setpassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  bindDomai,
  bindEmail,
  bindPhone,
  resetPassword,
} from "@/api/me.js";
import { getCode } from "@/api/signRegister.js";
import md5 from "js-md5";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      password:'',
      setpasswordShow:false,
      phone: "",
      code: "",
      verificationShow: false,
      show: true,
      count: "",
      timer: null,
      domainShow: false,
      ruleForm: {
        domainValue: "",
      },

      rules: {
        domainValue: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      ruleForm2: {
        code:'',
        phone:'',
        // old_password: "",
        new_password: "",
      },
      rules2: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        // old_password: [
        //   { required: true, message: "请输入旧密码", trigger: "blur" },
        // ],
        new_password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },

      title: "",
      name: "",
    };
  },
  //方法集合
  methods: {
    setpassword(){
       resetPassword({ old_password: '', new_password: this.password }).then(res => {
        this.$message.success("绑定成功")
        localStorage.setItem("type",1)
        this.$store.state.userType=1
        this.setpasswordShow=false
      })
    },
    // 点击编辑
    clickEdit(title, name) {
      if(title=='设置密码'){
      this.setpasswordShow=true
        return
      }
      this.title = title;
      this.name = name;
      if (this.name == "个性域名") {
         this.ruleForm.domainValue=this.userInfo.domain
      }
      if (this.name == "电子邮箱") {
        this.ruleForm.domainValue = this.userInfo.email;
      }
      this.domainShow = true;
    },
    // 点击确定
    determine(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.name == "个性域名") {
            bindDomai({ domain: this.ruleForm.domainValue  })
              .then(({ data }) => {
                console.log(data, "个性成功");
                this.ruleForm.domainValue = "";
                this.$store.commit('SET' , {key:'userInfo.refresh' ,value:true})
                this.domainShow = false;
                this.$message.success("绑定成功");
              })
              .catch((err) => {
                console.log(err, "个性失败");
              });
          }
          if (this.name == "电子邮箱") {
            console.log(this.ruleForm.domainValue);
            bindEmail({ email: this.ruleForm.domainValue })
              .then(({ data }) => {
                this.ruleForm.domainValue = "";
                this.$store.commit('SET' , {key:'userInfo.refresh' ,value:true})

                this.domainShow = false;
                this.$message.success("绑定成功");
                console.log(data, "个性成功");
              })
              .catch((err) => {
                console.log(err, "个性失败");
              });
          }
          if (this.name == "重置密码") {
            resetPassword({
              type:2,
              phone:this.ruleForm2.phone,
              code:this.ruleForm2.code,
              // old_password: this.ruleForm2.old_password,
              new_password: this.ruleForm2.new_password,
            })
              .then(({ data }) => {
                // this.ruleForm2.old_password = "";
                this.ruleForm2.new_password = "";
                this.$store.commit('SET' , {key:'userInfo.refresh' ,value:true})

                console.log(data, "重置成功");
                this.domainShow = false;

                this.$message.success("修改成功,请退出重新登录");
              })
              .catch((err) => {
                console.log(err, "重置失败");
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.domainShow = false;
    },

    // 点击编辑手机号
    bindPhone() {
      this.verificationShow = true;
    },
    // 确定手机号修改
    determinePhon() {
      if (!this.phone) {
        return this.$message({
          message: "手机号不能为空",
          type: "error",
          duration: 1800,
        });
      }
      if (this.phone.length != 11) {
        return this.$message({
          message: "手机号格式不正确",
          type: "error",
          duration: 1800,
        });
      }
      if (!this.code) {
        return this.$message({
          message: "验证码不能为空",
          type: "error",
          duration: 1800,
        });
      }

      bindPhone({ phone: this.phone, code: this.code })
        .then(({ data }) => {
          this.$message.success("修改成功");

          console.log(data, "手机号绑定成功");
        })
        .catch((err) => {
          console.log(err, "手机号绑定失败");
        });
    },
    // 点击获取验证码
    clickGetCode() {
      if (!this.show) {
        return;
      }
      if (!this.phone) {
        return this.$message({
          message: "手机号不能为空",
          type: "error",
          duration: 1800,
        });
      }
      if (this.phone.length != 11) {
        return this.$message({
          message: "手机号格式不正确",
          type: "error",
          duration: 1800,
        });
      }
      let phone = this.phone - 0;
      let time_stamp = new Date().getTime();
      let sign = md5(md5(phone + "bibao" + time_stamp));
      getCode({ phone: phone, time_stamp: time_stamp, sign: sign })
        .then((res) => {
          console.log(res, "验证码成功");
          this.$message.success("验证码发送成功");
          this.getCode();
        })
        .catch((error) => {
          console.log(error, "验证码失败");
          return;
        });
    },
    // 点击获取验证码
    clickGetCodereset() {
      if (!this.show) {
        return;
      }
      if (!this.ruleForm2.phone) {
        return this.$message({
          message: "手机号不能为空",
          type: "error",
          duration: 1800,
        });
      }
      if (this.ruleForm2.phone.length != 11) {
        return this.$message({
          message: "手机号格式不正确",
          type: "error",
          duration: 1800,
        });
      }
      let phone = this.ruleForm2.phone - 0;
      let time_stamp = new Date().getTime();
      let sign = md5(md5(phone + "bibao" + time_stamp));
      getCode({ phone: phone, time_stamp: time_stamp, sign: sign })
        .then((res) => {
          console.log(res, "验证码成功");
          this.$message.success("验证码发送成功");
          this.getCode();
        })
        .catch((error) => {
          console.log(error, "验证码失败");
          return;
        });
    },
    // 60S验证码
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    // 点击编辑绑定个性
    handleClose() {},
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
::v-deep{
  .el-input__inner {
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
  }
  .el-form-item__label{
    height: 40px;
    line-height: 40px;
  }
}
.default {
  color: #d4d4d4;
}
.bindipt {
  display: flex;
  margin: 10% 0;
  span {
    height: 40px;
    line-height: 40px;

    display: inline-block;
    white-space: nowrap;
    // padding: 0 5%;
    text-align: center;
    width: 45%;
    letter-spacing: 1px;
    background-color: #00957e;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}
.bindPhone {
  .el-button {
    margin-top: 20%;

    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    background: #00957e;
    font-weight: 600;
    color: #ffffff;
    border-color: #00957e;
  }
}
::v-deep {
  .el-form {
    width: 100%;
  }
  .el-dialog__header {
    text-align: center;
  }
}
.ipt {
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
  }
}
.account {
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 3.1% 3.3%;
  height: 50vh;

  .title {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #191919;
    line-height: 33px;
    margin-bottom: 4%;
  }
  .info_box {
    display: flex;
    width: 100%;
    margin-bottom: 3%;
    .info {
      width: 100%;
    }
    .name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 20px;
      margin-right: 7%;
      display: inline-block;
      width: 60px;
    }
    .content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 20px;
    }
  }
  .edit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    //color: #191919;
    line-height: 20px;
    white-space: nowrap;
    margin-right: 20%;
    cursor: pointer;
  }
}
.ipt {
    display: flex;
    span {
      height: 40px;
      line-height: 40px;

      display: inline-block;
      white-space: nowrap;
      // padding: 0 5%;
      text-align: center;
      width: 45%;
      letter-spacing: 1px;
      background-color: #00957e;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
</style>